import { corpCustomerInvoices } from "@telia/b2b-rest-client";
import {
  InvoicePreview,
  PreviewInvoiceSubSpecs,
  PreviewInvoiceSubscription,
  PreviewInvoiceSubscriptionListItem,
  PreviewInvoiceDrawerError,
} from "../interfaces/IPreviewInvoiceState";

export const getInvoicePreview = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemID: string
) => {
  return new Promise<InvoicePreview>((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.getInvoicePreview(
      scopeId,
      tscid,
      invoiceId,
      billingSystemID
    )
      .then((data: corpCustomerInvoices.InvoicePreview) => {
        const mappedData = _mapInvoicePreview(data);
        resolve(mappedData);
      })
      .catch(() => reject(PreviewInvoiceDrawerError.INVOICE_INFORMATION));
  });
};

export const getInvoicePreviewSubscriptionList = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemID: string
) => {
  return new Promise<PreviewInvoiceSubSpecs>((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.getInvoicePreviewSubSpecs(
      scopeId,
      tscid,
      invoiceId,
      billingSystemID
    )
      .then((data: corpCustomerInvoices.SubSpecificationResponse) => {
        const mappedInvoicePreviewSubscriptionList: PreviewInvoiceSubSpecs = _mapInvoicePreviewSubspecs(
          data
        );
        resolve(mappedInvoicePreviewSubscriptionList);
      })
      .catch(() => reject(PreviewInvoiceDrawerError.SUBSCRIPTION_LIST));
  });
};

export const getPdfSize = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemID: string
) => {
  return new Promise<number>((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.getPdfSize(
      scopeId,
      tscid,
      invoiceId,
      billingSystemID
    )
      .then((data: corpCustomerInvoices.PdfSizeResponse) => {
        resolve(data.pageTotal || 0);
      })
      .catch((e) => reject(e));
  });
};

const _mapInvoicePreview = (data: corpCustomerInvoices.InvoicePreview): InvoicePreview => {
  return {
    invoiceNumber: data.invoiceId || "",
    accountNumber: data.billingAccount || "",
    invoiceDate: data.invoiceDate || "",
    invoiceDueDate: data.dueDate || "",
    bankAccount: data.bankAccount || "",
    ocrNumber: data.ocr || "",
    totalAmount: data.totalSum || "",
    noOfSubscriptions: data.noOfSubscriptions || "",
  };
};

const _mapInvoicePreviewSubspecs = (response: corpCustomerInvoices.SubSpecificationResponse) => {
  return {
    shouldShowSubSpecs: response.shouldNotShowSpecs ? false : true,
    subscriptionList: _mapInvoicePreviewSubscriptionList(response.subSpecs || []),
  };
};

const _mapInvoicePreviewSubscriptionList = (
  mappedInvoicePreviewSubscriptionList: corpCustomerInvoices.SubSpecification[]
) => {
  return mappedInvoicePreviewSubscriptionList.map(
    (sub: corpCustomerInvoices.SubSpecification): PreviewInvoiceSubscription => ({
      subscriptionId: sub.number || "",
      reference: sub.reference || "",
      netTotal: _changeMinusCharCode(sub.netTotal || ""),
      taxTotal: _changeMinusCharCode(sub.taxTotal || ""),
      fixedFee: _mapSubscriptionListItem(sub.fixedFeeSpecRows || []),
      variableFee: _mapSubscriptionListItem(sub.variableFeeSpecRows || []),
      usage: _mapSubscriptionListItem(sub.usageSpecRows || []),
      oneTime: _mapSubscriptionListItem(sub.oneTimeSpecRows || []),
    })
  );
};

const _changeMinusCharCode = (value: string) => {
  return value.replace("−", "-").replace(" ", " ");
};

const _mapSubscriptionListItem = (subscriptionList: corpCustomerInvoices.SpecificationRow[]) => {
  return subscriptionList.map(
    (item: corpCustomerInvoices.SpecificationRow): PreviewInvoiceSubscriptionListItem => ({
      subscriptionName: item.text || "",
      net: item.net || "",
    })
  );
};
