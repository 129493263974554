<template>
  <li>
    <div class="subscription-information">
      <div class="subscription--row">
        <div>
          <telia-heading
            t-id="invoice-subscription-list-item-subscriptionId"
            tag="h3"
            variant="title-200"
          >
            {{ subscriptionId }}
          </telia-heading>
        </div>
        <telia-button
          v-if="showDropDownToggler"
          t-id="invoice-subscription-list-item-toggle-collapse"
          variant="text"
          @click="toggleCollapsed"
          class="collapsable__button"
          :ally-label="allyLabelForExtraInformation"
          :ally-controls="'collapsable__content--' + subscriptionId"
          :ally-expanded="!collapsed"
        >
          <telia-icon
            :name="collapsed ? 'chevron-down' : 'chevron-up'"
            size="md"
            class="icon"
          ></telia-icon>
        </telia-button>
      </div>
      <div class="subscription--row subscription--row">
        <telia-p
          t-id="invoice-subscription-list-item-accountReference"
          variant="paragraph-100"
          class="title__gray"
        >
          {{ reference }}
        </telia-p>
      </div>

      <div
        v-if="showDropDownToggler"
        class="collapsed__wrapper"
        :class="{ collapsed: collapsed }"
        :id="'collapsable__content--' + subscriptionId"
        :aria-hidden="collapsed"
        tabindex="-1"
      >
        <div t-id="invoice-subscription-list-item-one-time" v-if="oneTime.length">
          <telia-heading tag="h4" variant="subsection-100"
            ><strong>{{ t("oneTimeHeader") }}</strong></telia-heading
          >
          <invoice-subscription-list-item-service
            v-for="(service, index) in oneTime"
            :key="service.subscriptionName"
            :subscriptionName="service.subscriptionName"
            :net="formatAmount(service.net)"
            :rowNumber="index"
          />
        </div>
        <div t-id="invoice-subscription-list-item-fixed-fee" v-if="fixedFee.length">
          <telia-heading tag="h4" variant="subsection-100"
            ><strong>{{ t("fixedFeeHeader") }}</strong></telia-heading
          >
          <invoice-subscription-list-item-service
            v-for="(service, index) in fixedFee"
            :key="service.subscriptionName"
            :subscriptionName="service.subscriptionName"
            :net="formatAmount(service.net)"
            :rowNumber="index"
          />
        </div>
        <div t-id="invoice-subscription-list-item-variable-fee" v-if="variableFee.length">
          <telia-heading tag="h4" variant="subsection-100"
            ><strong>{{ t("variableFeeHeader") }}</strong></telia-heading
          >
          <invoice-subscription-list-item-service
            v-for="(service, index) in variableFee"
            :key="service.subscriptionName"
            :subscriptionName="service.subscriptionName"
            :net="formatAmount(service.net)"
            :rowNumber="index"
          />
        </div>
        <div t-id="invoice-subscription-list-item-usage" v-if="usage.length">
          <telia-heading tag="h4" variant="subsection-100"
            ><strong>{{ t("usageHeader") }}</strong></telia-heading
          >
          <invoice-subscription-list-item-service
            v-for="(service, index) in usage"
            :key="service.subscriptionName"
            :subscriptionName="service.subscriptionName"
            :net="formatAmount(service.net)"
            :rowNumber="index"
          />
        </div>
      </div>
      <div class="subscription--row subscription--row__padded">
        <telia-p
          t-id="invoice-subscription-list-item-amountWithoutVat"
          variant="paragraph-100"
          class="title__gray"
        >
          {{ t("amountWithoutVat") }}
        </telia-p>
        <telia-p t-id="invoice-subscription-list-item-netTotalValue" variant="paragraph-100"
          ><strong>{{ formatAmount(netTotal) }}</strong> SEK</telia-p
        >
      </div>
      <div class="subscription--row subscription--row">
        <telia-p
          t-id="invoice-subscription-list-item-taxTotalHeader"
          variant="paragraph-100"
          class="title__gray"
        >
          {{ t("taxTotalHeader") }}
        </telia-p>
        <telia-p t-id="invoice-subscription-list-item-taxTotalValue" variant="paragraph-100"
          >{{ formatAmount(taxTotal) }} SEK</telia-p
        >
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translateMixin } from "../locale/index";
import { PreviewInvoiceSubscriptionListItem } from "../interfaces/IPreviewInvoiceState";
import invoiceSubscriptionListItemService from "./invoice-subscription-list-item-service.vue";
import { previewInvoiceDrawershowDropDownEnabled } from "../featureToggles";
import gaTracking from "../composables/track-ga";

const t = translateMixin.methods.t;
const { trackEvent, analytics } = gaTracking();

interface IProps {
  subscriptionId: string | number;
  reference: string;
  netTotal: string;
  taxTotal: string;
  fixedFee: Array<PreviewInvoiceSubscriptionListItem>;
  variableFee: Array<PreviewInvoiceSubscriptionListItem>;
  usage: Array<PreviewInvoiceSubscriptionListItem>;
  oneTime: Array<PreviewInvoiceSubscriptionListItem>;
}

defineProps<IProps>();

const collapsed = ref(true);

const allyLabelForExtraInformation = () => {
  const translationKey = collapsed
    ? "allyLabels.showExtraInformation"
    : "allyLabels.hideExtraInformation";
  return t(translationKey);
};

const toggleCollapsed = () => {
  collapsed.value = !collapsed.value;
  if (!collapsed.value) {
    trackEvent(
      analytics.label.DROPDOWN_MORE_INFORMATION_ARROW_PREVIEW_INVOICE,
      analytics.action.CLICK
    );
  }
};

const formatAmount = (amount: number | string) => {
  return typeof amount === "string" ? amount : new Intl.NumberFormat("sv-SE").format(amount);
};

const showDropDownToggler = previewInvoiceDrawershowDropDownEnabled();
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/colors/mixins.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/borders/variables.scss";
// @import "~@teliads/components/foundations/typography/mixins.scss";

li {
  border-bottom: $telia-border-width-1 solid $telia-gray-50;
  min-height: $telia-spacing-96;
  padding: $telia-spacing-20 0;
}

.invoice-subscription-list__wrapper {
  margin-top: $telia-spacing-20;
  padding: $telia-spacing-16;
}

.subscription-information {
  min-width: 100%;
}

.subscription--row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__padded {
    padding-top: $telia-spacing-8;
  }
}

.title__gray {
  @include telia-color("gray-700");
}

.collapsable__button {
  align-self: center;

  .icon {
    @include telia-color("black");
  }
}

.collapsed {
  display: none;
}

.collapsed__wrapper {
  width: 100%;
  telia-heading {
    @include telia-color("gray-700");
  }

  & > div {
    padding-top: $telia-spacing-20;
  }
}
</style>
