export const SELECTOR_DIRECTION = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

export const SELECTOR_KEYS = {
  NETTOTAL: "netTotal",
  SUBSCRIPTIONID: "subscriptionId",
  REFERENCE: "reference",
};

export const SELECTOR_OPTIONS = {
  DEFAULT: "default",
  NETTOTAL_DESCENDING: `${SELECTOR_KEYS.NETTOTAL}_${SELECTOR_DIRECTION.DESCENDING}`,
  NETTOTAL_ASCENDING: `${SELECTOR_KEYS.NETTOTAL}_${SELECTOR_DIRECTION.ASCENDING}`,
  SUBSCRIPTIONID_DESCENDING: `${SELECTOR_KEYS.SUBSCRIPTIONID}_${SELECTOR_DIRECTION.DESCENDING}`,
  SUBSCRIPTIONID_ASCENDING: `${SELECTOR_KEYS.SUBSCRIPTIONID}_${SELECTOR_DIRECTION.ASCENDING}`,
  REFERENCE_DESCENDING: `${SELECTOR_KEYS.REFERENCE}_${SELECTOR_DIRECTION.DESCENDING}`,
  REFERENCE_ASCENDING: `${SELECTOR_KEYS.REFERENCE}_${SELECTOR_DIRECTION.ASCENDING}`,
};
