import { computed, ref } from "vue";
import { PreviewInvoiceDrawerError } from "../interfaces/IPreviewInvoiceState";

const errorType = ref<PreviewInvoiceDrawerError>(PreviewInvoiceDrawerError.NONE);
const errorFetchingPdfSize = ref(false);

const errorHandling = () => {
  const hasSubscriptionError = computed(() => {
    return errorType.value === PreviewInvoiceDrawerError.SUBSCRIPTION_LIST;
  });

  return { errorType, hasSubscriptionError, errorFetchingPdfSize };
};

export default errorHandling;
