<template>
  <div t-id="preview-invoice-payment-information" class="paymentInformation__wrapper">
    <telia-heading
      tag="h3"
      variant="title-100"
      t-id="payment-information-header"
      class="information-header"
      >{{ t("mybusiness.payment_information") }}</telia-heading
    >

    <div class="paymentInformation__grid">
      <div t-id="payment-information-ocr-number" class="ocrNumber">
        <telia-heading tag="h4" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.ocr_number") }}
          </strong></telia-heading
        >
        <div>
          <telia-p variant="paragraph-100" id="ocr-number">{{
            invoiceInformation.ocrNumber
          }}</telia-p>
          <b2x-copy-text-on-click
            id-to-copy="ocr-number"
            v-if="invoiceInformation.ocrNumber"
            @click="copyClicked('Copy_Ocr')"
            :ally-label="`${t('copy')} ${t('mybusiness.ocr_number')}`"
          />
        </div>
      </div>

      <div t-id="payment-information-invoice-due-date" class="invoiceDueDate">
        <telia-heading tag="h4" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.invoiceDueDate") }}
          </strong></telia-heading
        >
        <telia-p variant="paragraph-100">{{ invoiceInformation.invoiceDueDate }}</telia-p>
      </div>

      <div t-id="payment-information-payment-status" class="statusBadge">
        <telia-status-badge :variant="paymentStatus.variant">
          {{ paymentStatus.content }}
        </telia-status-badge>
      </div>

      <div t-id="payment-information-invoice-date" class="invoiceDate">
        <telia-heading tag="h4" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.invoiceDate") }}
          </strong></telia-heading
        >
        <telia-p variant="paragraph-100">{{ invoiceInformation.invoiceDate }}</telia-p>
      </div>

      <div t-id="payment-information-bankgiro" class="bankgiro">
        <telia-heading tag="h4" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.bank_giro") }}
          </strong></telia-heading
        >
        <div>
          <telia-p variant="paragraph-100" id="bankgiro">{{
            invoiceInformation.bankAccount
          }}</telia-p>
          <b2x-copy-text-on-click
            id-to-copy="bankgiro"
            v-if="invoiceInformation.bankAccount"
            @click="copyClicked('Copy_Bankgiro')"
            :ally-label="`${t('copy')} ${t('mybusiness.bank_giro')}`"
          />
        </div>
      </div>

      <div t-id="payment-information-total-services" class="totalServices">
        <telia-p variant="paragraph-100">
          {{ t("mybusiness.number_of_services") }}:
          <span variant="paragraph-100">{{ invoiceInformation.noOfSubscriptions }}</span>
        </telia-p>
      </div>

      <div t-id="payment-information-total-amount-text" class="totalAmountText">
        <telia-p variant="paragraph-100"> {{ t("amountWithVat") }} </telia-p>
      </div>

      <div t-id="payment-information-total-amount" class="totalAmount">
        <telia-heading tag="h4" variant="title-500"
          >{{ totalAmount() }}<span class="paragraph-100"> SEK</span></telia-heading
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PAYMENT_STATUS_BADGE } from "../consts/payment_status_badges";
import { translateMixin } from "../locale";
import invoiceInformationData from "../composables/invoice-information-data";
import gaTracking from "../composables/track-ga";

const t = translateMixin.methods.t;

const { invoiceInformation } = invoiceInformationData();
const { trackEvent, analytics } = gaTracking();

const paymentStatus = {
  variant: PAYMENT_STATUS_BADGE[invoiceInformation.value.paymentStatus].variant,
  content: t(PAYMENT_STATUS_BADGE[invoiceInformation.value.paymentStatus].translationKey),
};

const totalAmount = () => {
  return typeof invoiceInformation.value.totalAmount == "number"
    ? new Intl.NumberFormat("sv-SE").format(invoiceInformation.value.totalAmount)
    : invoiceInformation.value.totalAmount;
};

const copyClicked = (label) => {
  trackEvent(label, analytics.action.CLICK);
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/typography/mixins.scss";
@import "./src/styles/invoice-information-card.scss";

.paragraph-100 {
  @include telia-paragraph-100;
}
</style>
