export enum PreviewInvoiceDrawerError {
  SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST",
  INVOICE_INFORMATION = "INVOICE_INFORMATION",
  NONE = "NONE",
}

export interface PreviewInvoiceSubSpecs {
  subscriptionList: PreviewInvoiceSubscription[];
  shouldShowSubSpecs: boolean;
}

export interface PreviewInvoiceSubscription {
  subscriptionId: number | string;
  reference: string;
  netTotal: string;
  taxTotal: string;
  fixedFee: PreviewInvoiceSubscriptionListItem[];
  variableFee: PreviewInvoiceSubscriptionListItem[];
  usage: PreviewInvoiceSubscriptionListItem[];
  oneTime: PreviewInvoiceSubscriptionListItem[];
}

export interface PreviewInvoiceSubscriptionListItem {
  subscriptionName: string;
  net: number | string;
}

export interface InvoiceInformation {
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDueDate: string;
  totalAmount: number | string;
  accountNumber: string;
  ocrNumber: string;
  bankAccount: string;
  paymentStatus: string;
  noOfSubscriptions: string;
  billingSystemID: string;
  tscid: string;
  scopeId: string;
  pdfSize: number;
}

export type PreviewInvoiceDrawerProps = {
  open: boolean;
  invoice: Invoice;
};

export interface Invoice {
  scopeId: string;
  tscid: string;
  invoiceNumber: string;
  paymentStatus: string;
  billingSystemID: string;
}

export interface InvoicePreview {
  invoiceNumber: string;
  accountNumber: string;
  invoiceDate: string;
  invoiceDueDate: string;
  bankAccount: string;
  ocrNumber: string;
  totalAmount: number | string;
  noOfSubscriptions: string;
}
