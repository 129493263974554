import { SELECTOR_DIRECTION, SELECTOR_KEYS } from "../consts/selector_options";
import { PreviewInvoiceSubscription } from "../interfaces/IPreviewInvoiceState";

const defaultSortingFunction = (a: PreviewInvoiceSubscription, b: PreviewInvoiceSubscription) => {
  return a < b ? 1 : -1;
};

const sortSubscriptionId = (
  a: PreviewInvoiceSubscription,
  b: PreviewInvoiceSubscription,
  key: string,
  order: string
) => {
  const valueA = parseInt(a[key].replace("-", ""));
  const valueB = parseInt(b[key].replace("-", ""));
  return order === SELECTOR_DIRECTION.ASCENDING ? valueA - valueB : valueB - valueA;
};

const sortNetTotal = (
  a: PreviewInvoiceSubscription,
  b: PreviewInvoiceSubscription,
  key: string,
  order: string
) => {
  const valueA = parseFloat(a[key].replace(",", ".").replace(" ", ""));
  const valueB = parseFloat(b[key].replace(",", ".").replace(" ", ""));
  return order === SELECTOR_DIRECTION.ASCENDING ? valueA - valueB : valueB - valueA;
};

const sortReference = (
  a: PreviewInvoiceSubscription,
  b: PreviewInvoiceSubscription,
  key: string,
  order: string
) => {
  if (a[key] > b[key]) return order === SELECTOR_DIRECTION.ASCENDING ? -1 : 1;
  if (a[key] < b[key]) return order === SELECTOR_DIRECTION.DESCENDING ? -1 : 1;
  return 0;
};

const createSortingFunction = (key: string, order: string) => {
  return (a: PreviewInvoiceSubscription, b: PreviewInvoiceSubscription) => {
    switch (key) {
      case SELECTOR_KEYS.NETTOTAL:
        return sortNetTotal(a, b, key, order);
      case SELECTOR_KEYS.SUBSCRIPTIONID:
        return sortSubscriptionId(a, b, key, order);
      case SELECTOR_KEYS.REFERENCE:
        return sortReference(a, b, key, order);
      default:
        return defaultSortingFunction(a, b);
    }
  };
};

export default createSortingFunction;
