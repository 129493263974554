<template>
  <div :t-id="`rowNumber-${rowNumber}`">
    <telia-p t-id="subscriptionName" variant="paragraph-100">{{ subscriptionName }}</telia-p>
    <telia-p t-id="net" variant="paragraph-100">{{ netText }}</telia-p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface IProps {
  subscriptionName: string;
  net: string;
  rowNumber: number;
}

const props = defineProps<IProps>();

const netText = computed(() => {
  return props.net ? `${props.net} SEK` : "";
});
</script>

<style lang="scss" scoped>
div {
  display: flex;
  justify-content: space-between;
}
</style>
