<template>
  <div t-id="invoice-preview-skeleton">
    <div class="invoice-information">
      <telia-skeleton></telia-skeleton>
      <telia-skeleton></telia-skeleton>
    </div>
    <div class="invoice-information-card paymentInformation__wrapper">
      <telia-skeleton class="heading"></telia-skeleton>
      <div class="paymentInformation__grid">
        <div class="ocrNumber">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="invoiceDueDate">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="statusBadge">
          <telia-skeleton class="badge"></telia-skeleton>
        </div>

        <div class="invoiceDate">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="bankgiro">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="totalServices">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="totalAmountText">
          <telia-skeleton></telia-skeleton>
        </div>

        <div class="totalAmount">
          <telia-skeleton></telia-skeleton>
        </div>
      </div>
    </div>
    <div class="invoice-selector">
      <telia-skeleton></telia-skeleton>
      <telia-skeleton></telia-skeleton>
    </div>
    <div class="invoice-subscription-list">
      <telia-skeleton v-for="i in 5" :key="i"></telia-skeleton>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/borders/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
@import "./src/styles/invoice-information-card.scss";

telia-skeleton {
  width: 100%;
}
div.invoice-information {
  display: flex;
  margin-top: $telia-spacing-16;
  margin-bottom: $telia-spacing-16;
  .statusBadge {
    justify-self: unset;
  }
  telia-skeleton {
    width: 14rem;
    height: 5rem;
    margin-right: $telia-spacing-48;
    margin-left: $telia-spacing-16;
  }
}

div.invoice-information-card {
  margin-bottom: $telia-spacing-48;
  div.statusBadge {
    justify-self: unset;
    padding-left: $telia-spacing-12;
  }
  div.totalServices telia-skeleton {
    height: 2rem;
  }
  div.totalAmount telia-skeleton {
    width: 15rem;
    height: 3.4rem;
  }

  telia-skeleton {
    height: $telia-spacing-32;
    &.heading {
      width: 20rem;
      height: 3rem;
      margin-bottom: 2rem;
    }
    &.badge {
      height: 2rem;
      margin-bottom: 2rem;
    }
  }
}

div.invoice-selector {
  margin: $telia-spacing-16;
  telia-skeleton {
    margin-bottom: $telia-spacing-48;
    &:first-of-type {
      height: 5.6rem;
    }
    &:last-of-type {
      height: 4.4rem;
    }
  }
}

div.invoice-subscription-list {
  margin: $telia-spacing-16;
  telia-skeleton {
    height: 8rem;
    margin-bottom: $telia-spacing-32;
    padding-bottom: $telia-spacing-16;
    border-bottom: $telia-border-width-1 solid $telia-gray-300;
  }
}
</style>
