<template>
  <div class="invoice-information">
    <div t-id="preview-invoice-number">
      <telia-heading tag="h3" variant="subsection-100"
        ><strong>
          {{ t("mybusiness.invoice.number") }}
        </strong></telia-heading
      >
      <telia-p variant="paragraph-100">{{ invoiceInformation.invoiceNumber }}</telia-p>
    </div>
    <div t-id="preview-invoice-account-number" class="billingAccountNumber">
      <telia-heading tag="h3" variant="subsection-100"
        ><strong>
          {{ t("mybusiness.billing.account") }}
        </strong></telia-heading
      >
      <telia-p variant="paragraph-100">
        <b2x-sspa-link variant="navigation" :href="createBillingAccountUrl" disable-visited>
          <telia-visually-hidden>{{ t("mybusiness.billing.account") }}</telia-visually-hidden>
          {{ invoiceInformation.accountNumber }}
        </b2x-sspa-link>
      </telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import invoiceInformationData from "../composables/invoice-information-data";
import { computed } from "vue";

const t = translateMixin.methods.t;
const { invoiceInformation } = invoiceInformationData();

const createBillingAccountUrl = computed(() => {
  return `/foretag/mybusiness/${invoiceInformation.value.scopeId}/fakturor/hantera-fakturering/${invoiceInformation.value.tscid}/${invoiceInformation.value.accountNumber}`;
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.invoice-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr;
  padding: $telia-spacing-16;
}

.billingAccountNumber {
  padding-left: $telia-spacing-12;
}
</style>
