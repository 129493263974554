export const PAYMENT_STATUS_BADGE = {
  UNKNOWN_STATUS: {
    variant: "neutral",
    translationKey: "mybusiness.status.unknown",
  },
  PARTLY_PAID_OR_CREDITED: {
    variant: "neutral",
    translationKey: "mybusiness.status.partially.paid",
  },
  FULLY_PAID: {
    variant: "positive",
    translationKey: "mybusiness.status.paid",
  },
  NOT_PAID: {
    variant: "caution",
    translationKey: "mybusiness.status.not.paid",
  },
  OVERDUE: {
    variant: "warning",
    translationKey: "mybusiness.status.overdue",
  },
};
