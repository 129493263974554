<template>
  <div class="pdfLinks">
    <div v-if="pageTotalOverSizeLimit">
      <pdf-links
        :pdfHref="pdfHref"
        :totalLinks="totalLinks"
        gaLabel="PDF-faktura"
        :pdfSize="pdfSize"
        @sendGa="sendGa"
      />
    </div>
    <telia-link
      v-else
      t-id="preview-invoice-pdf-link"
      variant="standalone"
      :href="pdfHref"
      @click="sendGa('PDF-faktura')"
      >{{ t("pdfLinks.single") }} <telia-icon name="external" size="sm" />
    </telia-link>
  </div>
</template>

<script setup lang="ts">
import invoiceInformationData from "../composables/invoice-information-data";
import { computed } from "vue";
import pdfLinks from "./pdf-links.vue";
import { translateMixin } from "../locale";
import { DOWNLOADABLE_PAGE_SIZE_LIMIT } from "../consts/pdf_size";
import gaTracking from "../composables/track-ga";

const t = translateMixin.methods.t;

const { invoiceInformation } = invoiceInformationData();
const { trackEvent, analytics } = gaTracking();

const pageTotalOverSizeLimit = computed(() => {
  return pdfSize.value > DOWNLOADABLE_PAGE_SIZE_LIMIT;
});

const totalLinks = computed(() => {
  return Math.ceil(pdfSize.value / DOWNLOADABLE_PAGE_SIZE_LIMIT);
});

const pdfHref = computed(() => {
  return `/.api/corp-customer-invoices/v1/${invoiceInformation.value.scopeId}/pdf?tscid=${invoiceInformation.value.tscid}&invoiceId=${invoiceInformation.value.invoiceNumber}&billingSystemID=${invoiceInformation.value.billingSystemID}`;
});

const pdfSize = computed(() => {
  return invoiceInformation.value.pdfSize;
});

const sendGa = (label: string) => {
  trackEvent(label, analytics.action.DOWNLOAD);
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.pdfLinks {
  margin-top: $telia-spacing-2;
}
</style>
