import {
  Invoice,
  PreviewInvoiceSubscription,
  PreviewInvoiceSubscriptionListItem,
  PreviewInvoiceDrawerError,
} from "../interfaces/IPreviewInvoiceState";
import { ref } from "vue";
import { getInvoicePreviewSubscriptionList } from "../services/corpCustomerInvoicesService";
import { SELECTOR_OPTIONS } from "../consts/selector_options";
import createSortingFunction from "../utils/sortingFunctions";
import gaTracking from "./track-ga";
import { GOOGLE_ANALYTICS_TRACK_OPTIONS } from "../consts/google-analytics-track-options";
import { logError } from "@telia/b2x-logging";

const subscriptionList = ref<PreviewInvoiceSubscription[]>([]);
const displayList = ref<PreviewInvoiceSubscription[]>([]);
const selectedOption = ref<string>(SELECTOR_OPTIONS.DEFAULT);
const pageNumber = ref<number>(1);
const paginatedListLength = ref<number>(0);
const pageSize = 10;
const searchInput = ref<string>("");
const shouldShowSubSpecsNotification = ref<boolean>(false);

const subscriptionListData = () => {
  const showSearchIcon = ref<boolean>(true);

  const { trackEvent, analytics } = gaTracking();

  const fetchSubscriptionList = async (invoice: Invoice) => {
    try {
      const response = await getInvoicePreviewSubscriptionList(
        invoice.scopeId,
        invoice.tscid,
        invoice.invoiceNumber,
        invoice.billingSystemID
      );

      subscriptionList.value = response.subscriptionList;
      shouldShowSubSpecsNotification.value = !response.shouldShowSubSpecs;
      setDisplayList();
    } catch {
      throw PreviewInvoiceDrawerError.SUBSCRIPTION_LIST;
    }
  };

  const setSelectedOption = (option: string) => {
    selectedOption.value = option;
    modifySubscriptionListGaTracking.value = GOOGLE_ANALYTICS_TRACK_OPTIONS.SORT;
    setDisplayList();
  };

  const paginationChange = (event: CustomEvent) => {
    const label = `Page selected ${event.detail.page}`;
    trackEvent(label, analytics.action.CLICK);
    pageNumber.value = event.detail.page;
    setDisplayList();
  };

  let timeout: number | undefined;
  const modifySubscriptionListGaTracking = ref(GOOGLE_ANALYTICS_TRACK_OPTIONS.NOT_SET);

  //TODO: Fix event type
  const handleInputChange = (event) => {
    searchInput.value = event.target.value ?? "";
    showSearchIcon.value = searchInput.value.length > 0;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      modifySubscriptionListGaTracking.value = GOOGLE_ANALYTICS_TRACK_OPTIONS.FILTER;
      //TODO: If the filtered list is over the pageSize the paginator shows a wrong page. Paginator needs to have a prop to reset the pageNumber to 1 (or any page the developer wants).
      pageNumber.value = 1;
      setDisplayList();
    }, 500);
  };

  const setDisplayList = () => {
    let tempList = [...subscriptionList.value];

    if (searchInput.value.length > 0) {
      tempList = filterSubscriptionListOnSearchText(tempList);

      const label = tempList.length ? "1" : "0";
      if (modifySubscriptionListGaTracking.value === GOOGLE_ANALYTICS_TRACK_OPTIONS.FILTER) {
        trackEvent(label, analytics.action.FILTER);
      }
    }

    if (selectedOption.value !== SELECTOR_OPTIONS.DEFAULT) {
      const [key, order] = selectedOption.value.split("_");
      const sortingFunction = createSortingFunction(key, order);
      tempList.sort(sortingFunction);

      if (modifySubscriptionListGaTracking.value === GOOGLE_ANALYTICS_TRACK_OPTIONS.SORT) {
        trackEvent(
          `${analytics.label.SORTING_PREVIEW_INVOICE}${selectedOption.value}`,
          analytics.action.CLICK
        );
      }
    }

    modifySubscriptionListGaTracking.value = GOOGLE_ANALYTICS_TRACK_OPTIONS.NOT_SET;

    paginatedListLength.value = tempList.length;
    const endSubscriptionIndex = pageSize * pageNumber.value;
    const startSubscriptionIndex = endSubscriptionIndex - pageSize;
    displayList.value = tempList.filter(
      (item, index) => index >= startSubscriptionIndex && index < endSubscriptionIndex
    );
  };

  const closeNotification = () => {
    shouldShowSubSpecsNotification.value = false;
  };

  const resetSubscriptionListData = () => {
    subscriptionList.value = [];
    displayList.value = [];
    selectedOption.value = SELECTOR_OPTIONS.DEFAULT;
    pageNumber.value = 1;
    paginatedListLength.value = 0;
    searchInput.value = "";
    shouldShowSubSpecsNotification.value = false;
  };

  return {
    fetchSubscriptionList,
    setSelectedOption,
    paginationChange,
    handleInputChange,
    closeNotification,
    resetSubscriptionListData,
    subscriptionList,
    displayList,
    selectedOption,
    showSearchIcon,
    paginatedListLength,
    shouldShowSubSpecsNotification,
  };
};

export default subscriptionListData;

const filterSubscriptionListOnSearchText = (tempList: PreviewInvoiceSubscription[]) => {
  try {
    const filterFunction = (
      item: PreviewInvoiceSubscription | PreviewInvoiceSubscriptionListItem
    ) => {
      return Object.keys(item).some((key) =>
        Array.isArray(item[key])
          ? item[key].some((deepItem: PreviewInvoiceSubscriptionListItem) => {
              return filterFunction(deepItem);
            })
          : item[key].toLocaleLowerCase().includes(searchInput.value.toLocaleLowerCase())
      );
    };
    return tempList.filter(filterFunction);
  } catch (error) {
    logError(
      "b2b-invoice-landing-page",
      `Failed to filter subscription list on search text - ${error}`
    );
    return tempList;
  }
};
