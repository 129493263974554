import {
  action,
  category,
  label,
  customDimensions,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";
import { ICustomDimension } from "@telia/b2b-web-analytics-wrapper/types/interfaces";

const gaTracking = () => {
  const trackEvent = async (
    label: string,
    action: string,
    customDimensions: ICustomDimension[] | undefined = undefined,
    cat = category.PREVIEW_INVOICE_DRAWER,
    value?: string | undefined
  ) => {
    await TrackEvent(cat, action, label, value, customDimensions);
  };

  const analytics = {
    category,
    action,
    label,
    customDimensions,
  };

  return { trackEvent, analytics };
};

export default gaTracking;
