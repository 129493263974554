<template>
  <div class="invoice-subscription-list__wrapper">
    <telia-list t-id="invoice-subscription-list" variant="column" list-tag="ul">
      <invoice-subscription-list-item
        v-for="sub in displayList"
        :key="sub.subscriptionId"
        :subscriptionId="sub.subscriptionId"
        :reference="sub.reference"
        :netTotal="sub.netTotal"
        :taxTotal="sub.taxTotal"
        :fixedFee="sub.fixedFee"
        :variableFee="sub.variableFee"
        :usage="sub.usage"
        :oneTime="sub.oneTime"
      />
    </telia-list>
  </div>
</template>

<script setup lang="ts">
import invoiceSubscriptionListItem from "./invoice-subscription-list-item.vue";
import subscriptionListData from "../composables/subscription-list-data";

const { displayList } = subscriptionListData();
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/borders/variables.scss";

.invoice-subscription-list__wrapper {
  padding: $telia-spacing-16;
}

li {
  border-bottom: $telia-border-width-1 solid $telia-gray-50;
  min-height: $telia-spacing-96;
}
</style>
