<template>
  <div>
    <telia-link
      v-for="index in totalLinks"
      :key="index"
      variant="standalone"
      :href="getLinkHref(index)"
      @click="sendGa(`${gaLabel} - sida ${pages(index)}`)"
    >
      {{ t("pdfLinks.multiple", { pages: pages(index) }) }}
      <telia-icon name="download" size="sm" />
    </telia-link>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import { DOWNLOADABLE_PAGE_SIZE_LIMIT } from "../consts/pdf_size";

const t = translateMixin.methods.t;

type Props = {
  pdfHref: string;
  pdfSize: number;
  totalLinks: number;
  gaLabel: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{ (e: "sendGa", label: string): void }>();

const getLinkHref = (index: number) => {
  const pageFrom = generatePageFrom(index);
  const pageTo = generatePageTo(index);
  return `${props.pdfHref}&pageFrom=${pageFrom}&pageTo=${pageTo}`;
};

const generatePageFrom = (index: number) => {
  return index === 1 ? index : (index - 1) * DOWNLOADABLE_PAGE_SIZE_LIMIT + 1;
};

const generatePageTo = (index: number) => {
  return Math.min(index * DOWNLOADABLE_PAGE_SIZE_LIMIT, props.pdfSize);
};

const pages = (index: number) => {
  const pageFrom = generatePageFrom(index);
  const pageTo = generatePageTo(index);
  return `${pageFrom}-${pageTo}`;
};

const sendGa = (label: string) => {
  emit("sendGa", label);
};
</script>
