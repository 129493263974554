<template>
  <div t-id="invoice-subscription-list-selector" class="selector">
    <telia-select
      :label="t('labels.selector')"
      :options="JSON.stringify(invoiceSelectorOptions)"
      @change="sortSubscriptionList"
    >
    </telia-select>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import { SELECTOR_OPTIONS } from "../consts/selector_options";
import { computed } from "vue";
import subscriptionListData from "../composables/subscription-list-data";

const { setSelectedOption, selectedOption } = subscriptionListData();
const t = translateMixin.methods.t;

const sortSubscriptionList = (event) => {
  setSelectedOption(event.target.value);
};

const invoiceSelectorOptions = computed(() => {
  return [
    {
      label: t(`selector.${SELECTOR_OPTIONS.DEFAULT}`),
      value: SELECTOR_OPTIONS.DEFAULT,
      selected: selectedOption.value == SELECTOR_OPTIONS.DEFAULT,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.NETTOTAL_DESCENDING}`),
      value: SELECTOR_OPTIONS.NETTOTAL_DESCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.NETTOTAL_DESCENDING,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.NETTOTAL_ASCENDING}`),
      value: SELECTOR_OPTIONS.NETTOTAL_ASCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.NETTOTAL_ASCENDING,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.SUBSCRIPTIONID_DESCENDING}`),
      value: SELECTOR_OPTIONS.SUBSCRIPTIONID_DESCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.SUBSCRIPTIONID_DESCENDING,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.SUBSCRIPTIONID_ASCENDING}`),
      value: SELECTOR_OPTIONS.SUBSCRIPTIONID_ASCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.SUBSCRIPTIONID_ASCENDING,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.REFERENCE_DESCENDING}`),
      value: SELECTOR_OPTIONS.REFERENCE_DESCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.REFERENCE_DESCENDING,
    },
    {
      label: t(`selector.${SELECTOR_OPTIONS.REFERENCE_ASCENDING}`),
      value: SELECTOR_OPTIONS.REFERENCE_ASCENDING,
      selected: selectedOption.value === SELECTOR_OPTIONS.REFERENCE_ASCENDING,
    },
  ];
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

div.selector {
  padding: $telia-spacing-16;
}
</style>
