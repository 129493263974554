import { ref } from "vue";
import {
  Invoice,
  InvoiceInformation,
  PreviewInvoiceDrawerError,
} from "../interfaces/IPreviewInvoiceState";
import { getInvoicePreview } from "../services/corpCustomerInvoicesService";

const defaultInvoiceInformation = {
  invoiceNumber: "",
  invoiceDate: "",
  invoiceDueDate: "",
  totalAmount: 0,
  accountNumber: "",
  ocrNumber: "",
  bankAccount: "",
  paymentStatus: "",
  noOfSubscriptions: "",
  billingSystemID: "",
  tscid: "",
  scopeId: "",
  pdfSize: 0,
};

const invoiceInformation = ref<InvoiceInformation>(defaultInvoiceInformation);

const invoiceInformationData = () => {
  const fetchInvoiceInformation = async (invoice: Invoice) => {
    try {
      const getInvoicePreviewResponse = await getInvoicePreview(
        invoice.scopeId,
        invoice.tscid,
        invoice.invoiceNumber,
        invoice.billingSystemID
      );

      invoiceInformation.value = {
        invoiceNumber: getInvoicePreviewResponse.invoiceNumber,
        invoiceDate: getInvoicePreviewResponse.invoiceDate,
        invoiceDueDate: getInvoicePreviewResponse.invoiceDueDate,
        totalAmount: getInvoicePreviewResponse.totalAmount,
        accountNumber: getInvoicePreviewResponse.accountNumber,
        ocrNumber: getInvoicePreviewResponse.ocrNumber,
        bankAccount: getInvoicePreviewResponse.bankAccount,
        paymentStatus: invoice.paymentStatus,
        noOfSubscriptions: getInvoicePreviewResponse.noOfSubscriptions,
        billingSystemID: invoice.billingSystemID,
        tscid: invoice.tscid,
        scopeId: invoice.scopeId,
        pdfSize: 0,
      };
    } catch {
      invoiceInformation.value = {
        invoiceNumber: invoice.invoiceNumber,
        invoiceDate: "",
        invoiceDueDate: "",
        totalAmount: "",
        accountNumber: "",
        ocrNumber: "",
        bankAccount: "",
        paymentStatus: invoice.paymentStatus,
        noOfSubscriptions: "",
        billingSystemID: invoice.billingSystemID,
        tscid: invoice.tscid,
        scopeId: invoice.scopeId,
        pdfSize: 0,
      };
      throw PreviewInvoiceDrawerError.INVOICE_INFORMATION;
    }
  };

  const resetInvoiceInformationData = () => {
    invoiceInformation.value = defaultInvoiceInformation;
  };

  return { fetchInvoiceInformation, resetInvoiceInformationData, invoiceInformation };
};

export default invoiceInformationData;
