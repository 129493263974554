<template>
  <telia-notification
    heading-tag="h3"
    status="information"
    :button-text="t('mybusiness.close')"
    :button-aria-label="t('allyLabels.closeNotification')"
    @vocaClose="closeNotification"
  >
    <span slot="heading">{{ t("notification.shouldNotShowSubSpecsHeader") }}</span>
    <telia-text-spacing slot="content">
      <telia-p v-html="t('notification.shouldNotShowSubSpecsContent')"></telia-p>
    </telia-text-spacing>
  </telia-notification>
</template>
<script setup lang="ts">
import { translateMixin } from "../locale";
import subscriptionListData from "../composables/subscription-list-data";

const t = translateMixin.methods.t;

const { closeNotification } = subscriptionListData();
</script>
<style lang="scss" scoped></style>
