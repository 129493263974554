<template>
  <div>
    <b2x-drawer
      t-id="invoice-preview"
      :heading="t('mybusiness.invoice.details')"
      position="right"
      :is-open="open"
      @drawerClose="closeDrawer"
    >
      <component :is="renderedComponent" />
    </b2x-drawer>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "./locale";
import { shallowRef, ref, onMounted, onBeforeUnmount } from "vue";
import InvoicePreviewContent from "./components/invoice-preview-content.vue";
import InvoicePreviewSkeleton from "./components/invoice-preview-skeleton.vue";
import InvoiceNotificationError from "./components/invoice-notification-error.vue";
import subscriptionList from "./composables/subscription-list-data";
import invoiceInformationData from "./composables/invoice-information-data";
import { logError } from "@telia/b2x-logging";
import { PREVIEW_INVOICE_ERRORS } from "./consts/preview_invoice_errors";
import { Invoice, PreviewInvoiceDrawerError } from "./interfaces/IPreviewInvoiceState";
import { getPdfSize } from "./services/corpCustomerInvoicesService";
import errorHandling from "./composables/error-handling";
import "@telia/b2x-paginator";
import gaTracking from "./composables/track-ga";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const handleOpeningDrawer = (event: Event) => {
  invoice.value = (event as CustomEvent).detail.invoice;
  open.value = true;
  testing.value = false;
  trackOpenDrawer();
  tryToFetchData();
};

onMounted(() => {
  window.document
    .querySelector("body")
    ?.addEventListener("openPreviewInvoiceDrawer", handleOpeningDrawer);
});

onBeforeUnmount(() => {
  window.document
    .querySelector("body")
    ?.removeEventListener("openPreviewInvoiceDrawer", handleOpeningDrawer);
});

const emit = defineEmits(["drawerClose"]);

const invoiceDefaultState: Invoice = {
  invoiceNumber: "",
  scopeId: "",
  tscid: "",
  paymentStatus: "",
  billingSystemID: "",
};

const invoice = ref<Invoice>(invoiceDefaultState);
const open = ref(false);
const testing = ref(true);

const {
  fetchInvoiceInformation,
  invoiceInformation,
  resetInvoiceInformationData,
} = invoiceInformationData();
const { fetchSubscriptionList, resetSubscriptionListData } = subscriptionList();
const { trackEvent, analytics } = gaTracking();

const renderedComponent = shallowRef(InvoicePreviewSkeleton);

const { errorType, errorFetchingPdfSize } = errorHandling();

const tryToFetchData = async () => {
  try {
    await fetchInvoiceInformation(invoice.value);
    await fetchSubscriptionList(invoice.value);

    renderedComponent.value = InvoicePreviewContent;
  } catch (error) {
    logError(
      "b2b-invoice-landing-page",
      PREVIEW_INVOICE_ERRORS[error as PreviewInvoiceDrawerError]
    );

    errorType.value = error as PreviewInvoiceDrawerError;
    try {
      trackEvent(analytics.label.ERROR_FETCHING_PREVIEW_INVOICE, analytics.action.ERROR);

      renderedComponent.value =
        error === PreviewInvoiceDrawerError.INVOICE_INFORMATION
          ? InvoiceNotificationError
          : InvoicePreviewContent;

      invoiceInformation.value.pdfSize = await getPdfSize(
        invoice.value.scopeId,
        invoice.value.tscid,
        invoice.value.invoiceNumber,
        invoice.value.billingSystemID
      );
    } catch (error) {
      errorFetchingPdfSize.value = true;
      logError("b2b-invoice-landing-page", PREVIEW_INVOICE_ERRORS.PDF);
    }
  }
};

let drawerOpenTime: number;

const getSecondsDrawerOpen = () => {
  const milliSecondsDiff = new Date().valueOf() - drawerOpenTime;
  const modalOpenedSeconds = (milliSecondsDiff / 1000).toFixed(0).toString();
  return modalOpenedSeconds;
};

const trackOpenDrawer = () => {
  drawerOpenTime = new Date().valueOf();
  trackEvent(analytics.label.OPEN_PREVIEW_INVOICE_DRAWER, analytics.action.OPEN);
};

const trackCloseDrawer = () => {
  trackEvent(
    analytics.label.CLOSED_PREVIEW_INVOICE_DRAWER,
    analytics.action.CLICK,
    undefined,
    analytics.category.PREVIEW_INVOICE_DRAWER,
    getSecondsDrawerOpen()
  );
};

const closeDrawer = () => {
  emit("drawerClose");
  invoice.value = invoiceDefaultState;
  open.value = false;
  renderedComponent.value = InvoicePreviewSkeleton;
  trackCloseDrawer();
  resetInvoiceInformationData();
  resetSubscriptionListData();
};
</script>

<style lang="scss" scoped></style>
