<template>
  <div>
    <telia-notification :status="status" heading-tag="h3">
      <span slot="heading">{{ t("notification.header") }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{ notificationText }}</telia-p>
      </telia-text-spacing>
    </telia-notification>
    <invoice-notification-error-pdf-links v-if="!errorFetchingPdfSize" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translateMixin } from "../locale";
import invoiceNotificationErrorPdfLinks from "./invoice-notification-error-pdf-links.vue";
import errorHandling from "../composables/error-handling";
const t = translateMixin.methods.t;

const { hasSubscriptionError, errorFetchingPdfSize } = errorHandling();

const status = computed(() => {
  return hasSubscriptionError.value && !errorFetchingPdfSize.value ? "information" : "error";
});

const notificationText = computed(() => {
  if (errorFetchingPdfSize.value) {
    return t("notification.contentPdfError");
  } else if (hasSubscriptionError.value) {
    return t("notification.contentSubscriptionListError");
  } else {
    return t("notification.contentInvoiceInformationError");
  }
});
</script>

<style lang="scss" scoped></style>
