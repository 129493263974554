<template>
  <div>
    <invoice-information />
    <invoice-information-card />
    <div v-if="hasSubscriptionError" class="subscription-error">
      <invoice-notification-error />
    </div>
    <div v-else>
      <template v-if="subscriptionList.length > 1">
        <invoice-subscription-search />
        <invoice-selector />
      </template>
      <invoice-subscription-list />
      <b2x-paginator
        v-if="paginatedListLength"
        :list-length="paginatedListLength"
        t-id="invoice-preview-paginator"
        default-page-size="10"
        show-page-size-selector="false"
        @paginationChange="paginationChange"
        :mobile-view="true"
      />
    </div>
    <invoice-notification-sub-specs
      v-if="shouldShowSubSpecsNotification"
      class="sub-specs-notification"
      t-id="not-show-sub-specs-notification"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import InvoiceInformation from "./invoice-information.vue";
import InvoiceInformationCard from "./invoice-information-card.vue";
import InvoiceSubscriptionList from "./invoice-subscription-list.vue";
import InvoiceSelector from "./invoice-selector.vue";
import InvoiceSubscriptionSearch from "./invoice-subscription-search.vue";
import InvoiceNotificationSubSpecs from "./invoice-notification-sub-specs.vue";
import InvoiceNotificationError from "./invoice-notification-error.vue";
import subscriptionListData from "../composables/subscription-list-data";
import errorHandling from "../composables/error-handling";
import { PreviewInvoiceDrawerError } from "../interfaces/IPreviewInvoiceState";

const {
  subscriptionList,
  paginationChange,
  paginatedListLength,
  shouldShowSubSpecsNotification,
} = subscriptionListData();

const { errorType } = errorHandling();

const hasSubscriptionError = computed(() => {
  return errorType.value === PreviewInvoiceDrawerError.SUBSCRIPTION_LIST;
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.subscription-error,
.sub-specs-notification {
  margin-top: $telia-spacing-8;
}
</style>
