import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

enum Feature {
  PREVIEW_INVOICE_DRAWER_SHOW_DROP_DOWN = "previewInvoiceDrawershowDropDown",
}

const toggles: Record<Feature, boolean> = {
  [Feature.PREVIEW_INVOICE_DRAWER_SHOW_DROP_DOWN]: false,
};

export const previewInvoiceDrawershowDropDownEnabled = (): boolean => {
  return isEnabled(Feature.PREVIEW_INVOICE_DRAWER_SHOW_DROP_DOWN);
};

function isEnabled(toggleName: Feature): boolean {
  return featureToggleIsEnabled(toggleName, toggles);
}
