<template>
  <div class="search" t-id="filter-subscriptions-search-wrapper">
    <telia-text-input @input="handleInputChange" :label="t('labels.search')">
      <div class="search-icon-wrapper">
        <telia-icon
          name="search"
          t-id="filter-subscriptions-search-icon"
          v-show="showSearchIcon"
        ></telia-icon>
      </div>
    </telia-text-input>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import subscriptionListData from "../composables/subscription-list-data";

const t = translateMixin.methods.t;

const { handleInputChange, showSearchIcon } = subscriptionListData();
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.search {
  margin-top: $telia-spacing-16;
  padding: $telia-spacing-16;
}

telia-text-input {
  position: relative;
}

.search-icon-wrapper {
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  pointer-events: none;
  color: $telia-gray-500;
}
</style>
